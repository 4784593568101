import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import './GlobalPaymentCardSlider.scss';

function GlobalPaymentCardSlider({ data, settings }) {
  return (
    <div className="gpayment-card-slider purple-dots mb-[13px] md:pb-0">
      <Slider {...settings}>
        {data.map(({ id, description, heading, icon }) => (
          <div key={id} className="w-full !flex px-[8px] md:px-[19px] h-full">
            <div className="flex flex-col">
              <div className="bg-cf-light-grey px-4 py-6 h-full rounded">
                <div className="w-[32px] md:w-[40px] h-[32px] md:h-[40px] mb-4">
                  <img
                    className="max-w-[32px] md:max-w-[40px] max-h-[32px] md:max-h-[40px] w-full"
                    src={icon}
                    loading="lazy"
                    alt=""
                  />
                </div>
                <div>
                  <h3 className="font-body font-bold md:font-semibold mb-[12px] text-[16px] leading-[22px]">
                    {heading}
                  </h3>
                  <div className="text-2sm md:text-base">{description}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

GlobalPaymentCardSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      icon: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.node,
    }),
  ),
  settings: PropTypes.shape({}),
};

GlobalPaymentCardSlider.defaultProps = {
  data: [],
  settings: {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // nextArrow: <SliderButton left={false} />,
    // prevArrow: <SliderButton />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
    ],
  },
};

export default GlobalPaymentCardSlider;
