import React from 'react';

export const sliderData = [
  {
    id: 0,
    icon: '/img/global-payments/1.svg',
    heading: (
      <>
        Save more than 50% on{' '}
        <h2 className="font-body inline">international wire transfers</h2>
      </>
    ),
    description:
      'Cashfree Payments offers competitive rates compared to traditional banks and popular third party platforms.',
  },
  {
    id: 1,
    icon: '/img/global-payments/3.svg',
    heading: (
      <>
        Receive payments in all<span className="block">major currencies</span>
      </>
    ),
    description: (
      <>
        Empower your customers to{' '}
        <h2 className="font-body inline">receive payments in USD</h2>, GBP, EUR,
        and CAD via our local receiving account.
        <div className="bg-cf-primary px-[16px] pt-[9px] pb-[12px] text-2sm text-white rounded relative mt-5">
          <div className="relative ml-[-21px]">
            <img
              src="/img/whatsmore.svg"
              alt="What's More"
              loading="lazy"
              width="89"
              height="29"
            />
          </div>
          You can also use our global swift accounts to let your customers
          receive payments in 30+ currencies.
        </div>
      </>
    ),
  },
  {
    id: 2,
    icon: '/img/global-payments/2.svg',
    heading: (
      <>
        Get your{' '}
        <h2 className="font-body inline">international payment settlements</h2>{' '}
        in INR
      </>
    ),
    description:
      'Your businesses and individual customers will get all their international receivables settled in INR after automatic currency conversion, no matter which currency they are being paid in.',
  },

  {
    id: 3,
    icon: '/img/global-payments/4.svg',
    heading: (
      <>
        Simplified<span className="block">FIRC Issuance</span>
      </>
    ),
    description:
      'Cashfree Payments simplifies the entire process of getting Foreign Inward Remittance Certificates for all international receivables, making documentation easier for your customers to avail tax exemptions.',
  },
];
export const internationPayments = [
  {
    id: 0,
    title: 'Neobanks and fintech companies',
    icon: '/img/icons/neo.svg',
  },
  { id: 1, title: 'Freelance platforms', icon: '/img/icons/freelance.svg' },
  {
    id: 2,
    title: 'Ecommerce marketplaces',
    icon: '/img/icons/ecommerce-marketplace.svg',
  },
];

export const heroLogoData = [
  {
    id: 0,
    src: 'paypal.svg',
    width: '74',
    height: '26',
    className: 'w-[81px] md:max-w-full',
    alt: 'Paypal',
  },
  {
    id: 1,
    src: 'dinersclub.svg',
    width: '115',
    height: '19',
    className: 'w-[86px] md:max-w-full',
    alt: 'Diners club',
  },
  {
    id: 2,
    src: 'americanexpress.png',
    width: '108',
    height: '22',
    className: 'w-[77px] md:max-w-[77px]',
    alt: 'American Express',
  },
  {
    id: 3,
    src: 'visa.png',
    width: '112',
    height: '51',
    className: 'w-[48px] md:max-w-[45px]',
    alt: 'Visa',
  },
  {
    id: 4,
    src: 'mastercard.svg',
    width: '103',
    height: '30',
    className: 'w-[122px] md:max-w-full',
    alt: 'Master Card',
  },
];
export const expandCards = [
  {
    id: 0,
    title: 'USD, GBP, EUR, and CAD',
    text: 'local currency support',
  },
  {
    id: 1,
    title: '30+ currencies',
    text: 'via Global Swift Account',
  },
  {
    id: 2,
    title: '180+ countries',
    text: 'across the globe',
  },
];
export const faqData = [
  {
    id: 0,
    q: (
      <>
        What is
        <h2 className="inline font-body ml-[0.5ch]">
          {' '}
          Foreign Inward remittance?
        </h2>
      </>
    ),
    a: (
      <p>
        Foreign Inward remittance refers to the transfer of money to Indian from
        anywhere outside India. For example, when you receive money in your
        Indian bank account from a business or an individual abroad, it is
        called foreign inward remittance.
      </p>
    ),
  },
  {
    id: 1,
    q: 'What is FIRC and why is it needed?',
    a: (
      <p>
        Foreign Inward Remittance Certificate (FIRC) is a document proof for any
        foreign transfer to India, in case of export of goods. For export of
        services, there will be no tax levied if there is an FIRC available with
        the business.FIRC is also important to avail export related schemes such
        as EPCG (Export promotion capital goods) and others.
      </p>
    ),
  },
];
