import React from 'react';
import FAQ from '../components/FAQ';
import GetStarted from '../components/GetStarted';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import GlobalPaymentCardSlider from '../components/Sliders/GlobalPaymentCardSlider/GlobalPaymentCardSlider';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import {
  sliderData,
  internationPayments,
  heroLogoData,
  expandCards,
  faqData,
} from '../content/global-payments';
import '../styles/global-payments.scss';

const GlobalPaymentsPage = (props) => {
  return (
    <Layout
      lightHeader
      {...props}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=international%20wire%20transfer&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=international%20wire%20transfer&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="International wire transfers at low cost | Cashfree Payments"
        description="International wire transfers made easy for your customers across the globe. Receive international payments in 30+ currencies across 180+ countries."
        url="https://cashfree.com/international-payments/international-wire-transfer/"
        keywords={[
          'International Payments',
          'Inward Remittance',
          'ACH payments',
          'swift payments',
          'Forex',
          'Foreign inward remittance',
          'International wire transfer',
          'International bank transfer',
          'International fund transfer',
        ]}
      />

      <section className="pt-[110px] md:pt-[50px] pb-[64px] md:pb-[60px] bg-cf-dark  gpayments-hero text-white overflow-hidden relative">
        <div className="container position-relative gradient-bg">
          <div className="flex flex-wrap flex-col lg:flex-row md:justify-between md:min-h-[600px]">
            <div className="relative z-[1] w-full lg:w-1/2 lg:self-center">
              <h2 className="hidden md:block text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Global Collections
              </h2>
              <div className="mb-5 text-xl md:text-2xl font-semibold max-w-[640px] font-heading">
                Receive <h1 className="inline">international wire transfers</h1>{' '}
                at <span className="whitespace-nowrap">low-cost</span>
              </div>
              <div className="text-opacity-80 mb-8 max-w-[580px] text-[16px] leading-[24px] md:text-2.5md">
                Our powerful payments infrastructure helps you enable
                individuals and businesses in India to expand their business
                beyond borders and receive payments in 30+ currencies.
              </div>
              <a
                className="button button-green wide w-full md:w-auto"
                href="https://www.cashfree.com/contact-sales?source-action=international%20wire%20transfer&action=Contact%20Sales&button-id=ContactSales_Hero">
                Contact Sales <span className="chevron" />
              </a>
            </div>
            <div className="w-full lg:w-1/2 flex justify-center relative pt-[48px] md:pt-0 globe-gradient lg:right-[-7%]">
              <video
                autoPlay
                muted
                playsInline
                loop
                width="1300"
                height="1300"
                className="inset-video chrome-video z-10 aspect-square"
                poster="/img/global-payments/placeholder.png">
                <source
                  src="/img/global-payments/hero-video.webm"
                  type="video/webm"
                />
                <source
                  src="/img/global-payments/hero-video.mp4"
                  type="video/mp4"
                />
              </video>
              <video
                autoPlay
                muted
                playsInline
                loop
                width="1300"
                height="1300"
                className="inset-video hidden safari-video z-10 aspect-square"
                poster="/img/global-payments/placeholder.png">
                <source
                  src="/img/global-payments/hero-video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-3 overflow-hidden relative">
        <div className="container">
          <div className="block md:hidden max-w-max md:mx-auto mb-2 text-sm font-semibold text-cf-primary pl-5  uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
            Global Collections
          </div>
          <div className="font-heading text-lg md:text-xl font-semibold md:text-center  md:max-w-[730px] md:mx-auto">
            <h2 className="inline">Inward Remittance</h2> simplified with the
            power of Virtual Accounts
          </div>
          <div className="text-2.5sm md:text-md text-cf-dark md:text-center pt-[12px] md:pt-[20px] pb-8 md:pb-[60px] md:max-w-[730px] md:mx-auto">
            With Cashfree Payments’{' '}
            <h2 className="font-body inline">virtual accounts</h2>, enable your
            customers to receive payments in INR. Let Cashfree Payments handle
            currency conversion and other hassles.
          </div>

          <GlobalPaymentCardSlider data={sliderData} />

          <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-center pt-[32px] lg:pt-0 mb-0 mt-4 md:mt-0 w-full md:w-auto">
            <a
              className="button button-green w-full md:w-auto"
              href="https://www.cashfree.com/contact-sales?source-action=international%20wire%20transfer&action=Contact%20Sales&button-id=ContactSales_Inward_Remittance_Simplified">
              Contact Sales <span className="chevron" />
            </a>
          </div>
        </div>
      </section>
      <section className="p-section-2 relative bg-cf-hero text-white gpayments-explore-section overflow-hidden">
        <div className="container relative z-[1]">
          <div className="w-full pb-10 font-heading text-[24px] leading-[34px] md:text-2lg text-center">
            Expand beyond borders with Cashfree&nbsp;Payments!
          </div>
          <div className="flex flex-wrap md:mx-[-19px]">
            {expandCards.map((d) => (
              <div
                className="w-full md:w-1/3 md:px-[19px] mb-4 md:mb-0 last:mb-0"
                key={d.id}>
                <div className="border border-cf-stroke h-full border-opacity-[0.33] rounded-[3px] bg-white bg-opacity-[0.03] p-5 md:px-6 md:py-8 text-center">
                  <div className="mb-[4px] text-4md md:text-2lg font-semibold">
                    {d.title}
                  </div>
                  <div className="text-cf-cold-purple text-2.5sm md:text-[16ox]">
                    {d.text}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="relative p-section-2 ">
        <div className="container">
          <div className="font-heading text-lg md:text-xl md:mx-auto md:text-center font-semibold max-w-[570px] pb-6 md:pb-8">
            <h2 className="inline font-heading">
              Accept international payments
            </h2>{' '}
            as easy as domestic!
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-center mb-8 md:mb-[48px]">
            {internationPayments.map((c) => (
              <div className="lg:mr-[24px] last:lg:mr-0 pl-0 lg:first:pl-0 px-[16px]  py-0 mb-3 md:mb-0 last:mb-0 md:py-[14px] flex items-center">
                <img src={c.icon} alt={c.title} width="32" height="32" />
                <div className="text-2sm md:text-[16px] pl-3 lg:pl-2 font-semibold ">
                  {c.title}
                </div>
              </div>
            ))}
          </div>
          <img
            alt=""
            src="/img/global-payments/international-payments.svg"
            width="1258"
            height="343"
            className="w-full hidden md:block"
          />
          <img
            alt=""
            src="/img/global-payments/international-payments-md.svg"
            width="343"
            height="965"
            className="w-full block md:hidden"
          />
        </div>
      </section>
      <section className="relative p-section-2 bg-cf-hero overflow-hidden">
        <div className="container">
          <div className="flex flex-wrap flex-col-reverse lg:flex-row rounded  gpayments-globe-section justify-end">
            <div className="w-full lg:w-4/12 xl:w-1/2 pt-[32px] lg:pt-0 lg:pr-[30px] self-end mb-[-50px] md:mb-[-98px]">
              <img
                className=" relative w-full max-w-[500px]"
                src="/img/global-payments/transaction.svg"
                width="500"
                height="492"
                alt=""
              />
            </div>
            <div className="w-full lg:w-8/12 xl:w-1/2 lg:pl-[30px]  lg:self-center text-white relative z-[1]">
              <div className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Introducing
              </div>
              <div className="mb-[20px] text-[24px] leading-[34px] md:text-[28px] md:leading-[39px] font-semibold font-heading">
                International Payment Gateway with Cashfree Payments
              </div>
              <div className="mb-[24px] text-cf-cold-purple text-2.5sm md:text-md">
                Take your business global, show your products in 100+ foreign
                currencies and give fastest checkout experience with PayPal
                direct integration!
              </div>
              <div className="text-white font-semibold text-2.5sm md:text-md">
                Have an existing PayPal business account?
              </div>
              <div className="text-cf-cold-purple mb-8 md:mb-10 text-2.5sm md:text-md">
                Connect your PayPal account to Cashfree Payments and get started
                right away.
              </div>
              <a
                className="button button-green  w-full md:w-auto text-2sm md:text-md px-[21px] md:px-[24px] md:py-[10px]"
                href="https://www.cashfree.com/help/127/exclusive-paypal-express-checkout-cashfree-payment-gateway">
                Try International Payment Gateway Now{' '}
                <span className="chevron" />
              </a>
              <div>
                <div className="text-white pt-8 pb-[12px]">
                  Payment modes supported
                </div>
                <div className="w-full relative">
                  <HeroLogoSlider
                    data={heroLogoData}
                    imgUrl="/img/global-payments/icons"
                    className="pl-[10px]"
                    itemClassName="mx-[10px] flex items-center align-center h-[26px]"
                    settings={{
                      dots: false,
                      arrows: false,
                      infinite: false,
                      autoplay: false,
                      autoplaySpeed: 0,
                      swipe: false,
                      pauseOnHover: false,
                      draggable: false,
                      centerMode: false,
                      speed: 2000,
                      slidesToShow: heroLogoData.length,
                      variableWidth: true,
                      cssEase: 'linear',
                      responsive: [
                        {
                          breakpoint: 600,
                          settings: {
                            draggable: false,
                            autoPlay: true,
                            autoplay: true,
                            speed: 2000,
                            swipe: false,
                            autoplaySpeed: 2000,
                            cssEase: 'linear',
                            slidesToShow: heroLogoData.length - 1,
                            infinite: true,
                            centerMode: false,
                            variableWidth: true,
                          },
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container general-faqs large-faqs">
        <FAQ
          open="0"
          supportLinkHref="https://www.cashfree.com/help/hc"
          data={faqData}
        />
      </div>
      <GetStarted
        content={
          <>
            With Cashfree Payments, collect payments, make payouts, manage
            <h2 className="inline font-body"> international payments</h2> and
            more. Start now by creating your account or get in touch to explore
            custom solutions.
          </>
        }
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=international%20wire%20transfer&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=international%20wire%20transfer&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
};

export default GlobalPaymentsPage;
